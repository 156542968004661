import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { finalize } from 'rxjs/internal/operators/finalize';
import { tap } from 'rxjs/internal/operators/tap';
import { map } from 'rxjs/operators';
import { Config } from '../config/env.config';
import { apiUrl } from '../utils';

@Injectable()
export class ShippingCompanySevice {
  pendingRequests = 0;
  apiURL: string = Config.apiUrl;

  constructor(private http: HttpClient, private router: Router) {
    // NProgress.configure({ showSpinner: true });
  }

  // Handle API errors
  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      // 	`Backend returned code ${error.status}, ` +
      // 	`body was: ${error.error}`
      // );
      return throwError(error.error);
    }
  }

  intercept(observable: Observable<Response>): Observable<Response> {
    this.pendingRequests++;
    // NProgress.start();

    return observable.pipe(
      tap(
        () => {
          // Do nothing
        },
        error => {
          console.log(error, 'error');
          if (error.status == 401) {
            // Redirect to login page
            // console.log(error)
            this.router.navigate(['/login'], {
              queryParams: { return_url: this.router.url },
            });
          }
        },
      ),
      finalize(() => {
        this.pendingRequests--;
        if (this.pendingRequests <= 0) {
          // NProgress.done();
        }
      }),
    );
  }

  login(email: string, password: string) {
    return this.http.post(
      apiUrl('login'),
      JSON.stringify({
        email: email,
        password: password,
      }),
    );
  }

  getRequest(path: string, _arguments?: HttpParams, type?: any) {
    // console.log({path});

    return this.intercept(
      this.http.get<any>(apiUrl(path)).pipe(catchError(this.handleError)),
    );
  }

  getCsvRequest(path: string): any {
    // console.log({path});
    //console.log(path);

    return this.http.get(path, { responseType: 'blob' }).pipe(
      map((data: any) => {
        return data;
      }, catchError(this.handleError)),
    );
  }
  getPdfRequest(path: string): any {
    // console.log({path});
    //console.log(path);

    return this.http.get(path, { responseType: 'blob' }).pipe(
      map((data: any) => {
        return data;
      }, catchError(this.handleError)),
    );
  }
  postmultipleBatchRequest(path: string, data?: Object): any {
    return this.http.post(path, data, { responseType: 'blob' }).pipe(
      map((data: any) => {
        return data;
      }, catchError(this.handleError)),
    );
  }
  postRequest(path: string, data?: Object) {
    return this.intercept(
      this.http
        .post<any>(apiUrl(path), data)
        .pipe(catchError(this.handleError)),
    );
  }

  postRequest1(path: string, data?: Object) {
    return this.http
      .post<any>(apiUrl(path), data)
      .pipe(
        map((resp: any) => {
          return resp.data;
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        }),
      )
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        throw error;
      });
    /* return this.intercept(
      this.http.post<any>(apiUrl(path), data).pipe(catchError(this.handleError))
    ); */
  }
  getMultipleBatchRequest(path: string, data?: Object) {
    //console.log(data);
    return this.intercept(
      this.http.get<any>(apiUrl(path), data).pipe(catchError(this.handleError)),
    );
  }

  putRequest(path: string, data?: Object) {
    return this.intercept(
      this.http.put<any>(apiUrl(path), data).pipe(catchError(this.handleError)),
    );
  }

  saveRequest(path: string, data = {}, id = '') {
    if (id) {
      path = `${path}/${id}`;
    }

    return this.postRequest(path, data);
  }

  deleteRequest(id: string, path: string) {
    return this.intercept(
      this.http.delete<any>(`${path}/${id}`).pipe(catchError(this.handleError)),
    );
  }
}
